.rotating-icons-container {
  position: relative;
  width: 250px; /* Container size */
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotating-icons {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container {
  position: absolute;
}

.icon {
  font-size: 40px; /* Icon size */
  color: black;
}
